.About-links img {
  width: 150px;
}

.About-links a {
  outline: none;
  transition: 100ms outline;
  padding: 1rem;
}

.About-links a:hover {
  outline: 2px solid darkblue;
}

.About-links {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-flow: wrap;
}

.About .bold {
  font-weight: bold;
}

.About-skills {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
}

.About-skills > div > div {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.About-skill-pill {
  font-size: 0.8rem;
  padding: 0.3rem;
  margin: 0.2rem;
  border: 1px solid black;
  border-radius: 10px;
}

.About-language {
  background-color: lightcyan;
}

.About-team {
  background-color: lightsalmon;
}

.About-framework {
  background-color: lightgoldenrodyellow;
}

.About-programming {
  background-color: lightpink;
}

.About-brooks {
  width: 20rem;
  float: left;
  margin: 0 2rem 0 0;
  border-radius: 10px;
}
