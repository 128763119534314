.App main {
  margin: 1rem 25rem;
  border-radius: 10px;
  border: 0.5px solid lightblue;
  padding: 1rem;
  transition: margin 250ms;
}

@media (max-width: 1700px) {
  .App main {
    margin: 1rem 5rem;
  }
}

@media (max-width: 1024px) {
  .App main {
    margin: 0;
  }
}

@media (max-width: 800px) {
  .App main {
    min-width: 750px;
    margin: 1rem;
    padding: 0;
    border: none;
  }
}
