body,
h1 {
  margin: 0;
}

body {
  font-size: 1.2rem;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1rem;
}

@media (max-width: 800px) {
  body {
    min-width: 1024px;
    font-size: 2rem;
  }
  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 1.5rem;
  }
}
