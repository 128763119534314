.TopNav {
  background-color: lightsteelblue;
  height: 3rem;
  line-height: 3rem;
  padding: 0 25rem;
  display: flex;
  justify-content: space-between;
  transition: padding 500ms;
}

.TopNav nav a {
  text-decoration: none;
  margin-right: 1rem;
  color: black;
  border-top: 2px solid lightsteelblue;
  transition: border-bottom 500ms;
}

.TopNav nav a:last-child {
  margin-right: 0;
}

.TopNav nav a:visited {
  color: black;
}

.TopNav-current {
  border-bottom: 2px solid black;
}

.TopNav img {
  height: 2.5rem;
}

.TopNav nav a img {
  height: 1rem;
}

.TopNav nav a:not(.TopNav-current):hover {
  border-bottom: 2px solid darkred;
}

.TopNav-dropdown {
  visibility: hidden;
  height: 3rem;
  text-align: center;
}

@media (max-width: 1700px) {
  .TopNav {
    padding: 0 5rem;
  }
}

@media (max-width: 1024px) {
  .TopNav {
    padding: 0;
  }
}

@media (max-width: 900px) {
  .TopNav-dropdown {
    visibility: visible;
    cursor: pointer;
    width: 10rem;
  }

  .TopNav nav {
    flex-direction: column;
    display: flex;
    z-index: 100;
  }

  .TopNav nav a {
    background-color: lightblue;
    padding: 1rem;
    visibility: hidden;
    margin-right: 0;
  }

  .TopNav nav:hover a {
    visibility: visible;
  }
}
